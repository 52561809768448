import React, { useEffect, useState } from "react";
import {
  CCardGroup,
  CCardFooter,
  CCol,
  CLink,
  CRow,
  CWidgetProgress,
  CWidgetIcon,
  CWidgetProgressIcon,
  CWidgetSimple,
  CProgress,
  CSelect,
  CButton,
} from "@coreui/react";
import WidgetsDropdown from "./WidgetsDropdown";
import DatePicker, { Calendar, DateObject } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";

const f = "YYYY/MM/DD hh:mm:ss:SSS";
export const SelectPeriodicTime = ({
  value,
  setValue,
  valueString,
  setValueString,
}) => {
  const [periodicity, setPeriodicity] = useState("WEEKLY");

  const onChange = (v) => {
    console.log("onChange");

    console.log(v);
    if (setValue) setValue(v);

    if (setValueString) {
      const stringified = v.map((date) => date.format(f));

      setValueString(stringified);
    }
  };
  const onValues = (value, valueString) => {
    console.log(onValues);
    console.log(value);

    if (value) return value;
    if (valueString)
      return valueString.map(
        (s) =>
          new DateObject({
            date: s,
            format: f,
          })
      );
  };
  const [props, setProps] = useState({
    value: onValues(value, valueString),
    format: "MM/DD/YYYY HH:mm:ss",
    onChange: onChange,
  });
  useEffect(() => {
    console.log("useEffect");
    onChange(props.value);
  }, [props.value]);

  const generateDates = (periodicity, values) => {
    const generatedDates = [...values];
    if (periodicity == "WEEKLY") {
      for (let i = 1; i < 24; i++) {
        for (const d of values) {
          console.log(d);
          const newDate = new DateObject(d).add(i * 7, "day");
          console.log(newDate);

          generatedDates.push(newDate);
        }
      }
    }
    if (periodicity == "MONTHLY") {
      for (let i = 1; i < 12; i++) {
        for (const d of values) {
          console.log(d);
          const newDate = new DateObject(d).add(i, "months");
          console.log(newDate);

          generatedDates.push(newDate);
        }
      }
    }
    setProps((state) => ({
      ...state,
      value: generatedDates,
    }));
  };

  return (
    <CCol>
      <CRow xs="6">
        <Calendar
          {...props}
          onPropsChange={setProps}
          multiple
          plugins={[<DatePanel />, <TimePicker position="bottom" />]}
        />
      </CRow>
      <div style={{ height: 20 }}></div>
      <CRow xs="6">
        <CCol xs="6">
          <CSelect
            custom
            placeholder="Shpeshtesia"
            onChange={setPeriodicity}
            value={periodicity}
            id="periodicity"
            readOnly={false}
            name="periodicity"
          >
            {["WEEKLY", "MONTHLY"].map((type) => (
              <option value={type}>{type}</option>
            ))}
          </CSelect>
        </CCol>
        <CCol xs="3">
          <CButton
            color="primary"
            variant="outline"
            shape="square"
            size="sm"
            onClick={() => {
              generateDates(periodicity, props.value);
            }}
          >
            GJENERO
          </CButton>
        </CCol>
        <CCol xs="3">
          <CButton
            color="primary"
            variant="outline"
            shape="square"
            size="sm"
            onClick={() => {
              setProps((state) => ({
                ...state,
                value: [],
              }));
            }}
          >
            RESET
          </CButton>
        </CCol>
      </CRow>
    </CCol>
  );
  return (
    <DatePicker
      value={value}
      onChange={setValue}
      multiple
      format="MM/DD/YYYY HH:mm:ss"
      plugins={[<DatePanel />, <TimePicker position="bottom" />]}
    />
  );

  // return (
  //   <input
  //     type="datetime-local"
  //     id="deadline"
  //     value={value}
  //     onChange={onChange}
  //   />
  // );
};

const SelectTaskTime = () => {
  const [tasks, setTasks] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [task, setTask] = useState("");
  const [priority, setPriority] = useState("top");
  const [deadline, setDeadline] = useState("");

  const handleTaskChange = (e) => {
    setTask(e.target.value);
  };

  const handlePriorityChange = (e) => {
    setPriority(e.target.value);
  };

  const handleDeadlineChange = (e) => {
    setDeadline(e.target.value);
  };

  const addTask = () => {
    if (task.trim() === "" || deadline === "") {
      alert("Please enter a task and select a valid deadline.");
      return;
    }

    const selectedDate = new Date(deadline);
    const currentDate = new Date();

    if (selectedDate <= currentDate) {
      alert("Please select a future date for the deadline.");
      return;
    }

    const newTask = {
      id: tasks.length + 1,
      task,
      priority,
      deadline,
      done: false,
    };

    setTasks([...tasks, newTask]);

    setTask("");
    setPriority("top");
    setDeadline("");
  };

  const markDone = (id) => {
    const updatedTasks = tasks.map((t) =>
      t.id === id ? { ...t, done: true } : t
    );
    setTasks(updatedTasks);

    const completedTask = tasks.find((t) => t.id === id);
    if (completedTask) {
      setCompletedTasks([...completedTasks, completedTask]);
    }
  };

  const upcomingTasks = tasks.filter((t) => !t.done);

  return (
    <div className="App">
      <header>
        <h1>Task Scheduler</h1>
      </header>
      <main>
        <SelectPeriodicTime value={deadline} onChange={handleDeadlineChange} />
      </main>
    </div>
  );
};

export default SelectTaskTime;
