import React from "react";
import { selectGLoader } from "../../redux/slices/gLoaderSlice";
import { useDispatch, useSelector } from "react-redux";
import { hideGModal, selectGModal } from "../../redux/slices/gModalSlice";
import { CModal, CModalBody } from "@coreui/react";
import ApproveModal from "./ApproveModal";
import GLoader from "./GLoader";

export const GModal = () => {
  const dispatch = useDispatch();

  const gModalState = useSelector(selectGModal);
  const onModalClose = () => {
    dispatch(hideGModal());
  };

  return (
    <CModal
      closeOnBackdrop={false}
      show={gModalState.show}
      onClose={onModalClose}
      size={gModalState.size || "sm"}
    >
      {gModalState.show && <ApproveModal />}
    </CModal>
  );
};
const ModalContainer = ({ children }) => {
  const gLoader = useSelector(selectGLoader);

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        {children}
      </div>
      {gLoader && <GLoader />}
    </div>
  );
};

export default ModalContainer;
