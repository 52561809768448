import { CSpinner } from "@coreui/react";
import React from "react";
import Text from "../../components/Text";

const GLoader = () => {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        // width: "100%",
        // height: "100%",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#00000050",
        display: "flex",
        zIndex: 2,
      }}
    >
      <div
        style={{
          alignSelf: "center",
          background: "white",
          borderRadius: 4,
          borderWidth: 2,
          borderColor: "grey",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",

          flexDirection: "row",
          paddingLeft: 16,
          paddingRight: 16,
          paddingTop: 8,
          paddingBottom: 8,
        }}
      >
        <CSpinner
          color="primary"
          style={{ width: 16, height: 16, marginRight: 8 }}
        />
        <Text>Loading</Text>
      </div>
    </div>
  );
};
export default GLoader;
