import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import ServiceApi from "../../api/ServiceApi";

export const tasksSlice = createSlice({
  name: "tasks",
  initialState: [],
  reducers: {
    setTasks: (state, action) => {
      return action.payload;
    },
  },
});

export const { setTasks } = tasksSlice.actions;

export const getTasks = (onSuccess) => async (dispatch) => {
  const tasks = await ServiceApi().getAllTasks();
  if (tasks) {
    onSuccess && onSuccess(tasks);

    dispatch(setTasks(tasks));
  }
};

export const createTask =
  (task, onSuccess, onError) => async (dispatch, getState) => {
    const { response, error } = await ServiceApi().createTask(task);

    if (response) {
      dispatch(getTasks(onSuccess));
    } else {
      onError(error.data.message);
    }
  };

export const updateTask =
  (task, onSuccess, onError) => async (dispatch, getState) => {
    const { response, error } = await ServiceApi().updateTask(task);

    if (response) {
      dispatch(getTasks(onSuccess));
    } else {
      onError && onError(error.data.message);
    }
  };
export const deleteTask =
  (task, onSuccess, onError) => async (dispatch, getState) => {
    const { response, error } = await ServiceApi().deleteTask(task);

    if (response) {
      dispatch(getTasks(onSuccess));
    } else {
      onError(error.data.message);
    }
  };

export const selectTasks = (state) => state.tasks;
export default tasksSlice.reducer;
