import React, { useState } from "react";
import { selectGLoader } from "../../redux/slices/gLoaderSlice";
import { useSelector } from "react-redux";
import { CButton, CModal, CModalBody } from "@coreui/react";
import { selectRoleRight } from "../../redux/slices/settingsSlice";

export const TYPE = { SUBMIT: "SUBMIT", CANCEL: "CANCEL", DELETE: "DELETE" };
export const LABEL = { SUBMIT: "Ruaj", CANCEL: "Anullo", DELETE: "Fshij" };
export const COLOR = { SUBMIT: "info", CANCEL: "secondary", DELETE: "danger" };

const MButton = ({ children, type, tag = "", onClick = () => {}, style }) => {
  const roleRight = useSelector(selectRoleRight);
  if (tag && !roleRight?.includes(tag)) {
    return <></>;
  }
  return (
    <>
      <CButton
        color={COLOR[type]}
        style={style}
        onClick={onClick}
        type={type == TYPE.SUBMIT ? "submit" : undefined}
      >
        {LABEL[type]}
      </CButton>
    </>
  );
};

export default MButton;
