import axios from "axios";
import { logOut } from "../redux/slices/meSlice";
import { store } from "../redux/store";
import _ from "lodash";
const baseServerUrl =
  process.env.BASE_SERVER_URL || process.env.REACT_APP_NODE_ENV == "development"
    ? "http://localhost:3001"
    : "https://verushi.com"; //"https://verushi.com"; //"https://f57ed6de2018.ngrok.app"; //"http://localhost:3001"; //"http://verushi.com"; //"http://verushi.com/v1/"; //"http://localhost:3001/v1/"; //"http://verushi.com/v1/"; //"http://localhost:3001/v1/"; //'http://161.97.106.172'
export const client = () => {
  const defaultOptions = {
    baseURL: `${baseServerUrl}/v1/`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  };

  let instance = axios.create(defaultOptions);

  instance.interceptors.request.use((config) => {
    const token = _.get(store.getState(), "me.tokens.access.token", ""); //localStorage.getItem('token');
    //console.log(token)
    console.log(`REQUEST: ${config.method}:${config.url}`);

    //console.log(store.getState().me.tokens)

    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      console.log(`RESPONSE: ${response.status}:${response.config.url}`);
      console.log(response.data);
      return responseHandler(response, null);
    },
    (error) => {
      console.log("response: error");
      console.log(error.response);
      const status =
        error && (error.status || (error.response && error.response.status));
      if (status && (status === 403 || status === 401)) {
        //localStorage.setItem('token','');
        store.dispatch(logOut());
      }
      return responseHandler(null, error.response); //Promise.reject(error)
    }
  );
  return instance;
};

export const responseHandler = (response, error) => {
  return { response, error };
};

export default client();
export const SERVER_URL = baseServerUrl;
