import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import ServiceApi from '../../api/ServiceApi'
import _ from 'lodash'
export const serviceActiveSlice = createSlice({
  name: 'serviceActive',
  initialState: [],
  reducers: {
    setProducts: (state, action) => {
      return  action.payload
    },
  }
})
export const serviceArchiveSlice = createSlice({
  name: 'serviceArchive',
  initialState: [],
  reducers: {
    setArchivedProducts: (state, action) => {
      return  action.payload
    },
  }
})
export const inventorySlice = createSlice({
  name: 'inventory',
  initialState: [],
  reducers: {
    setInventoryProducts: (state, action) => {
      return  action.payload
    },
  }
})
export const categoriesSlice = createSlice({
  name: 'categories',
  initialState: [],
  reducers: {
    setCategories: (state, action) => {
      return  action.payload
    },
  }
})
export const brandsSlice = createSlice({
  name: 'brands',
  initialState: [],
  reducers: {

    setBrands: (state, action) => {
      return  action.payload
    },
  }
})

export const { setProducts } = serviceActiveSlice.actions
export const { setInventoryProducts } = inventorySlice.actions

export const { setArchivedProducts  } = serviceArchiveSlice.actions
export const { setCategories } = categoriesSlice.actions
export const { setBrands } = brandsSlice.actions

export const getProducts = (onSuccess) => async (dispatch) => {
  const products = await ServiceApi().getAllProducts()
  if (products) {
    onSuccess && onSuccess(products)
    dispatch(setProducts(products))
  }
}

export const getArchivedProducts = (onSuccess) => async (dispatch) => {
  const products = await ServiceApi().getAllArchivedProducts()
  if (products) {
    onSuccess && onSuccess(products)
    dispatch(setArchivedProducts(products))
  }
}
export const getInventoryProducts = (onSuccess) => async (dispatch) => {
  const products = await ServiceApi().getAllInventoryProducts()
  if (products) {
    onSuccess && onSuccess(products)
    dispatch(setInventoryProducts(products))
  }
}
export const createProduct = (product, onSuccess, onError) => async (dispatch, getState) => {
  const {response,error} = await ServiceApi().createProduct(product)

  if (response) {
    dispatch(getProducts(onSuccess))
  }else{
    onError(error.data.message)
  }
}

export const archive = (id,  onSuccess, onError) => async (dispatch, getState) => {
  const {response,error} =  await ServiceApi().archive(id)

  if (response) {
    dispatch(getProducts(onSuccess))
  }else{
    onError(error.data.message)
  }
}
export const unarchive = (id,  onSuccess, onError) => async (dispatch, getState) => {
  const {response,error} =  await ServiceApi().unArchive(id)

  if (response) {
    dispatch(getArchivedProducts(onSuccess))
  }else{
    onError(error.data.message)
  }
}
export const updateProduct = (product,  onSuccess, onError) => async (dispatch, getState) => {
  console.log(product)
  const {response,error} =  await ServiceApi().updateProduct(product)

  if (response) {
    dispatch(getProducts(onSuccess))
  }else{
    onError(error.data.message)
  }
}
export const getCategoriesAndBrands = ( onSuccess, onError)=>async (dispatch)=>{
  const categories = await ServiceApi().getCategories()

  if (categories !== null) {
    dispatch(setCategories(categories))
  }

  const brands = await ServiceApi().getBrands()

  if (brands !== null) {

    dispatch(setBrands(brands))
  }
  onSuccess && onSuccess(categories)

}
export const selectAllProducts = state => state.serviceActive
export const selectActiveProducts = state =>  state.serviceActive
export const selectHistoryProducts = state =>  state.serviceArchive
export const selectInventoryProducts = state =>  state.inventory

export const selectCategories = state => state.categories
export const selectBrands = state => state.brands

export default {
  brands: brandsSlice.reducer,
  categories: categoriesSlice.reducer,
  serviceArchive: serviceArchiveSlice.reducer,
  serviceActive: serviceActiveSlice.reducer,
  inventory:inventorySlice.reducer
}