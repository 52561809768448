import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import ServiceApi from "../../api/ServiceApi";
import { setGLoader } from "./gLoaderSlice";
export const balanceSlice = createSlice({
  name: "balances",
  initialState: [],
  reducers: {
    setBalances: (state, action) => {
      return action.payload;
    },
  },
});

export const { setBalances } = balanceSlice.actions;
export const getBalances = (onSuccess) => async (dispatch) => {
  try {
    const finance = await ServiceApi().getBalances();
    console.log(finance);
    if (finance) {
      onSuccess && onSuccess(finance);
      dispatch(setBalances(finance));
    }
  } catch (error) {}

  dispatch(setGLoader(false));
};
export const transferBalances =
  (approverId, onSuccess, onError) => async (dispatch, getState) => {
    dispatch(setGLoader(true));

    const { response, error } = await ServiceApi().transferBalance(approverId);

    if (response) {
      dispatch(getBalances(onSuccess));
    } else {
      onError(error.data.message);
      dispatch(setGLoader(false));
    }
  };

export const selectBalance = (state) => state.balances;
export const selectMyBalance = (state) => {
  const myId = state.me?.user?.id;
  console.log("selectMyBalance", myId);
  console.log(state.balances);

  const selected = state.balances.filter((g) => g.id == myId);
  return selected.length > 0 ? selected[0] : null;
};
export const selectXBalance = (state, myId) => {
  const selected = state.balances.filter((g) => g.id == myId);
  return selected.length > 0 ? selected[0] : null;
};

export default balanceSlice.reducer;
