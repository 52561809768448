import React, { useEffect, useState, useRef } from "react";
import {
  CModalFooter,
  CForm,
  CFormText,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";

import { useDispatch, useSelector } from "react-redux";

import _ from "lodash";

import ModalContainer from "../component/ModalContainer";
import MButton, { TYPE } from "../component/MButton";
import {
  MODAL_TYPE,
  hideGModal,
  selectGModal,
} from "../../redux/slices/gModalSlice";
import { deleteInvoice, deleteUnit } from "../../redux/slices/unitSlice";
import { deleteBuilding, getBuildings } from "../../redux/slices/buildingSlice";
import { deleteGroup } from "../../redux/slices/groupSlice";
import { deleteUser } from "../../redux/slices/usersSlice";
import { deleteComplaint } from "../../redux/slices/complaintsSlice";

const ApproveModal = () => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");

  const gModalState = useSelector(selectGModal);

  const onSuccess = () => {
    dispatch(hideGModal());
  };

  const onError = (msg) => {
    setErrorMessage(msg);
  };
  const onDeleteClicked = (e) => {
    e.stopPropagation();
    e.preventDefault();

    switch (gModalState.type) {
      case MODAL_TYPE.DELETE_UNIT:
        dispatch(deleteUnit(gModalState.params, onSuccess, onError));
        break;
      case MODAL_TYPE.DELETE_OBJECT:
        dispatch(deleteBuilding(gModalState.params, onSuccess, onError));
        break;
      case MODAL_TYPE.DELETE_GROUP:
        dispatch(deleteGroup(gModalState.params, onSuccess, onError));
        break;
      case MODAL_TYPE.DELETE_USER:
        dispatch(deleteUser(gModalState.params, onSuccess, onError));
        break;
      case MODAL_TYPE.DELETE_COMPLAINT:
        dispatch(deleteComplaint(gModalState.params, onSuccess, onError));
        break;
      case MODAL_TYPE.DELETE_INVOICE:
        dispatch(
          deleteInvoice(
            gModalState.params.buildingId,
            gModalState.params.invoiceId,
            ()=>{
              if(gModalState.params.refresh &&gModalState.params.refresh =='Group'){
                dispatch(getBuildings(gModalState.params.groupId,onSuccess))
              }else{

              onSuccess()
              }
            },
            onError
          )
        );
        break;

      default:
        break;
    }
  };

  const onCancelClick = (e) => {
    e.preventDefault();
    dispatch(hideGModal());
  };

  const getActionButtons = () => {
    return (
      <>
        <MButton
          type={TYPE.DELETE}
          onClick={onDeleteClicked}
          style={{ marginRight: "8px" }}
        ></MButton>

        <MButton type={TYPE.CANCEL} onClick={onCancelClick}></MButton>
      </>
    );
  };

  return (
    <ModalContainer>
      <CForm>
        <CModalHeader closeButton>
          <CModalTitle>Jeni i sigurt qe doni ta fshini?</CModalTitle>
        </CModalHeader>

        <CModalFooter>
          {errorMessage && (
            <CFormText className="help-block mb-3" color={"danger"}>
              {errorMessage}
            </CFormText>
          )}

          {getActionButtons()}
        </CModalFooter>
      </CForm>
    </ModalContainer>
  );
};

export default ApproveModal;
