
import React from 'react'
import styled from 'styled-components'

const getPrimaryFontFamily = (props) => {
  const fontPrefix = 'Roboto'
  return fontPrefix

  // const fontSuffix = getFontWeight(props)
  // return `${fontPrefix}-${fontSuffix}`
}
const getSecondaryFontFamily = (props) => {
  const fontPrefix = 'Raleway'
  return fontPrefix

  // const fontSuffix = getFontWeight(props)
  //
  // return `${fontPrefix}-${fontSuffix}`
}
const getFontWeight = (props) => {
  const a = props.fonttype
  let platform
  let weight = 400
  switch (a) {
    case 'h1':
      weight = 300
      break
    case 'h2':
      weight = 500
      break
    case 'h3':
      weight = 500
      break
    case 'h4':
      weight = 700
      break
    case 'h5':
      weight = 500
      break
    case 'h6':
      weight = 400
      break
      case 'h7':
        weight = 700
        break
    case 'p':
      weight = 400
      break
    case 'th':
      weight = 700
      break
    default: weight = 400
  }
  return `font-weight: ${weight}`
}

/* istanbul ignore next */
const H1 = styled.p`
  font-family: ${getSecondaryFontFamily};
  ${getFontWeight};
  font-size: 36px;
  letter-spacing: 0px;
  line-height: 42px;
  color: ${(props) => props.fontcolor};
  text-transform: ${(props) => props.texttransform};
  text-align: ${(props) => props.align || 'left'};
    margin-bottom: 0;

`
/* istanbul ignore next */
const H2 = styled.p`
  font-family: ${getSecondaryFontFamily};
  ${getFontWeight};
  font-size: 30px;
  letter-spacing: 0px;
  line-height: 35px;
  color: ${(props) => props.fontcolor};
  text-transform: ${(props) => props.texttransform};
  text-align: ${(props) => props.align || 'left'};
    margin-bottom: 0;

`
/* istanbul ignore next */
const H3 = styled.p`
  font-family: ${getSecondaryFontFamily};
  ${getFontWeight};
  font-size: 24px;
  letter-spacing: 0px;
  line-height: 28px;
  color: ${(props) => props.fontcolor};
  text-transform: ${(props) => props.texttransform};
  text-align: ${(props) => props.align || 'left'};
    margin-bottom: 0;

`
/* istanbul ignore next */
const H4 = styled.p`
  font-family: ${getPrimaryFontFamily};
  ${getFontWeight};
  font-size: 18px;
  letter-spacing: 0px;
  line-height: 21px;
  color: ${(props) => props.fontcolor};
  text-transform: ${(props) => props.texttransform};
  text-align: ${(props) => props.align || 'left'};
  margin-bottom: 0;
`
/* istanbul ignore next */
const H5 = styled.p`
  font-family: ${getPrimaryFontFamily};
  ${getFontWeight};
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 18px;
  color: ${(props) => props.fontcolor};
  text-transform: ${(props) => props.texttransform};
  text-align: ${(props) => props.align || 'left'};
    margin-bottom: 0;

`
/* istanbul ignore next */
const H6 = styled.p`
  font-family: ${getPrimaryFontFamily};
  ${getFontWeight};
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 14px;
  color: ${(props) => props.fontcolor};
  text-transform: ${(props) => props.texttransform};
  text-align: ${(props) => props.align || 'left'};
  margin-bottom: 0;
`
/* istanbul ignore next */
const H7 = styled.p`
  font-family: ${getPrimaryFontFamily};
  ${getFontWeight};
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 14px;
  color: ${(props) => props.fontcolor};
  text-transform: ${(props) => props.texttransform};
  text-align: ${(props) => props.align || 'left'};
  margin-bottom: 0;
`
/* istanbul ignore next */
const TableHeader = styled.p`
  font-family: ${getPrimaryFontFamily};
  ${getFontWeight};
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 14px;
  color: ${(props) => props.fontcolor};
  text-transform: ${(props) => props.texttransform};
  text-align: ${(props) => props.align || 'left'};
  margin-bottom: 0;
`
/* istanbul ignore next */
const Paragraph = styled.p`
  font-family: ${getPrimaryFontFamily};
  ${getFontWeight};
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 24px;
  color: ${(props) => props.fontcolor};
  text-transform: ${(props) => props.texttransform};
  text-align: ${(props) => props.align || 'left'};
  margin-bottom: 0;
`
/* istanbul ignore next */
const Container = styled.div`
  ${(props) => props.align ? `align-items: ${props.align};` : ''}
  ${(props) => props.ellipsis && 'width: 100%;'}
`

// add for testing purpose
Container.displayName = 'Container'
H1.displayName = 'H1'
H2.displayName = 'H2'
H3.displayName = 'H3'
H4.displayName = 'H4'
H5.displayName = 'H5'
H6.displayName = 'H6'
H7.displayName = 'H7'

Paragraph.displayName = 'Paragraph'
TableHeader.displayName = 'TableHeader'

const textTypes = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
  h7: H7,

  p: Paragraph,
  th: TableHeader
}

/**
 * Text Component that allows various font style, weight, size and line height
 * Component can be used for React Native and Web projects
 */
function Text (props) {
  const { testID, text, children, ellipsis, fontColor, onPress, textTransform, linkify, align, numberOfLines, textSize, noScaling } = props
  /**
   * Fix warning, styled components takes in 1/0 for booleans
   */
  const formatedEllipsis = ellipsis ? 1 : 0
  const getText = () => {
    const defaultTextSize = textSize || 'p'
    const CustomText = textTypes[defaultTextSize]
    const allowFontScaling = !noScaling
    let ellipsisStyling = ''
    if (ellipsis) {
      ellipsisStyling = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: 'inherit',
        whiteSpace: 'nowrap'
      }
      /* ellipsisStyling = `
        overflow: hidden;
        text-overflow: ellipsis;
        width: inherit;
        white-space: nowrap;
      ` */
    }

    const linkedText = (linkify, text) => {
      const linkedText =
        <CustomText
          testID={testID || 'Text'}
          data-cy={testID || 'Text'}
          fonttype={textSize}
          fontcolor={fontColor}
          align={align || 'left'}
         // onPress={onPress}
          numberOfLines={numberOfLines}
          texttransform={textTransform || 'none'}
          allowFontScaling={allowFontScaling}
          ellipsis={formatedEllipsis}
          ellipsisstyling={ellipsisStyling}
        >
          {text}
        </CustomText>

      const renderedText =
          <CustomText
            style={{ ...ellipsisStyling }}
            fonttype={textSize}
            testID={testID || 'Text'}
            data-cy={testID || 'Text'}
            fontcolor={fontColor}
            align={align || 'left'}
            // onPress={onPress}
            numberOfLines={numberOfLines}
            texttransform={textTransform || 'none'}
            allowFontScaling={allowFontScaling}
            ellipsizeMode='tail'
          >
            {text}
          </CustomText>

      return renderedText
    }

    return (
      <>{linkedText(linkify, text)}{children}</>
    )
  }
  const bodyText = getText()

  return (
    <Container align={align} ellipsis={formatedEllipsis}>
      {bodyText}
    </Container>
  )
}

export default Text
