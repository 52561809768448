import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import ServiceApi from "../../api/ServiceApi";

export const MODAL_TYPE = {
  DELETE_UNIT: "DELETE_UNIT",
  DELETE_OBJECT: "DELETE_OBJECT",
  DELETE_GROUP: "DELETE_GROUP",
  DELETE_USER: "DELETE_USER",
  DELETE_INVOICE: "DELETE_INVOICE",
  DELETE_COMPLAINT: "DELETE_COMPLAINT",
};

export const gModalSlice = createSlice({
  name: "gModal",
  initialState: { show: false, type: "", params: null, size: "sm" },
  reducers: {
    setGModal: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setGModal } = gModalSlice.actions;

export const showGModal =
  (type, params, size = "sm") =>
  async (dispatch) => {
    dispatch(setGModal({ show: true, type, params, size }));
  };
export const hideGModal = () => async (dispatch) => {
  dispatch(setGModal({ show: false, type: "", params: null, size: "sm" }));
};

export const selectGModal = (state) => state.gModal;

export default gModalSlice.reducer;
