import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import ServiceApi from "../../api/ServiceApi";

export const gLoaderSlice = createSlice({
  name: "gLoader",
  initialState: false,
  reducers: {
    setGLoading: (state, action) => {
      return action.payload;
    },
  },
});

export const { setGLoading } = gLoaderSlice.actions;

export const setGLoader = (active) => async (dispatch) => {
  if (active) {
    dispatch(setGLoading(true));
    setTimeout(() => {
      dispatch(setGLoading(false));
    }, 5000);
  } else {
    dispatch(setGLoading(false));
  }
};

export const selectGLoader = (state) => state.gLoader;

export default gLoaderSlice.reducer;
