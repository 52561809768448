import { createSlice } from '@reduxjs/toolkit'

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState: {
    sidebarShow: 'responsive'
  },
  reducers: {
    setSideBarShow:(state, action) => {
      state.sidebarShow = action.payload
    },
  }
})

export const { setSideBarShow } = navigationSlice.actions
export const sideBarShow= state => state.navigation.sidebarShow
export default navigationSlice.reducer
