import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import ServiceApi from "../../api/ServiceApi";
import { getGroups } from "./groupSlice";
export const buildingsSlice = createSlice({
  name: "buildings",
  initialState: [],
  reducers: {
    setBuildings: (state, action) => {
      return action.payload;
    },
  },
});

export const { setBuildings } = buildingsSlice.actions;
export const getBuildings = (groupId, onSuccess) => async (dispatch) => {
  const buildings = await ServiceApi().getAllBuildings(groupId);
  if (buildings) {
    onSuccess && onSuccess(buildings);
    dispatch(setBuildings(buildings));
    dispatch(getGroups());
  }
};

export const createBuilding =
  (building, onSuccess, onError) => async (dispatch, getState) => {
    const { response, error } = await ServiceApi().createBuilding(building);

    if (response) {
      dispatch(getBuildings(building.groupId, onSuccess));
    } else {
      onError(error.data.message);
    }
  };

export const updateBuilding =
  (building, onSuccess, onError) => async (dispatch, getState) => {
    const { response, error } = await ServiceApi().updateBuilding(building);

    if (response) {
      dispatch(getBuildings(building.groupId, onSuccess));
    } else {
      onError(error.data.message);
    }
  };
export const deleteBuilding =
  (building, onSuccess, onError) => async (dispatch, getState) => {
    const { response, error } = await ServiceApi().deleteBuilding(building);

    if (response) {
      dispatch(getBuildings(building.groupId, onSuccess));
    } else {
      onError(error.data.message);
    }
  };

export const selectBuildings = (state) => state.buildings;
export const selectBuildingsByGroupId = (state, groupId) =>
  (state.buildings || []).filter((b) => b.groupId == groupId);

export const selectBuildingById = (state, id) => {
  console.log("selectBuildingById", id);
  const selected = state.buildings.filter((g) => g.id == id);
  console.log(selected);
  return selected.length > 0 ? selected[0] : null;
};
export default buildingsSlice.reducer;
