import "./scss/style.scss";
import React, { useEffect, useState } from "react";
import { HashRouter, Route, Switch, useHistory } from "react-router-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/es/integration/react";
import { store, persistor } from "./redux/store";
import styled from "styled-components";
import "./App.css";
import { selectToken } from "./redux/slices/meSlice";
import { getSettings, selectSettings } from "./redux/slices/settingsSlice";
import { getUsers } from "./redux/slices/usersSlice";
import { CSpinner, CTextarea } from "@coreui/react";
import Text from "./components/Text";
import { selectGLoader } from "./redux/slices/gLoaderSlice";
import { GModal } from "./views/component/ModalContainer";
import GLoader from "./views/component/GLoader";
import { getBalances } from "./redux/slices/balanceSlice";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: row;
`;

const NavigationContainer = styled.div`
  display: flex;
  width: 200px;
  background: black;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 20;
  ${(props) => (props.isLogin ? "display: none;" : "")}
`;
const PageContainer = styled.div`
  display: flex;
  flex: 1;
  position: relative;

  ${(props) => (props.isLogin ? "top: 0; bottom: 0;" : "")}

  overflow-y: hidden;
`;

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const DocView = React.lazy(() => import("./views/pages/docView/DocView"));

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={loading} persistor={persistor}>
        <HashRouter>
          <AppNavigator />
        </HashRouter>
      </PersistGate>
    </Provider>
  );
};

const AppNavigator = () => {
  const history = useHistory();

  const authenticated = useSelector(selectToken);
  const dispatch = useDispatch();
  const gLoader = useSelector(selectGLoader);

  useEffect(() => {
    console.log(authenticated);
    if (!authenticated) {
      history.replace("/login");
    }
  }, [authenticated, history]);

  useEffect(() => {
    dispatch(getSettings());
    dispatch(getUsers());
    dispatch(getBalances());
  }, []);

  return (
    <>
      <Container>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/doc/:docPath"
              name="DocView"
              render={(props) => <DocView {...props} />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </Container>
      {gLoader && <GLoader />}
      <GModal />
    </>
  );
};
export default App;
