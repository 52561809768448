import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import ServiceApi from "../../api/ServiceApi";
import { setGLoader } from "./gLoaderSlice";

export const complaintsSlice = createSlice({
  name: "complaints",
  initialState: [],
  reducers: {
    setComplaints: (state, action) => {
      return action.payload;
    },
  },
});

export const { setComplaints } = complaintsSlice.actions;

export const getComplaints = (onSuccess) => async (dispatch) => {
  dispatch(setGLoader(true));

  const complaints = await ServiceApi().getAllComplaints();
  if (complaints) {
    onSuccess && onSuccess(complaints);

    dispatch(setComplaints(complaints));
  }
  dispatch(setGLoader(false));
};

export const createComplaint =
  (complaint, onSuccess, onError) => async (dispatch, getState) => {
    dispatch(setGLoader(true));

    const { response, error } = await ServiceApi().createComplaint(complaint);

    if (response) {
      dispatch(getComplaints(onSuccess));
    } else {
      onError(error.data.message);
    }
    dispatch(setGLoader(false));
  };

export const updateComplaint =
  (complaint, onSuccess, onError) => async (dispatch, getState) => {
    const { response, error } = await ServiceApi().updateComplaint(complaint);

    if (response) {
      dispatch(getComplaints(onSuccess));
    } else {
      onError && onError(error.data.message);
    }
  };
export const deleteComplaint =
  (complaint, onSuccess, onError) => async (dispatch, getState) => {
    const { response, error } = await ServiceApi().deleteComplaint(complaint);

    if (response) {
      dispatch(getComplaints(onSuccess));
    } else {
      onError(error.data.message);
    }
  };

export const selectComplaints = (state) => state.complaints;
export const selectBuildingComplaints = (state, buildingId) => {
  console.log("selectBuildingComplaints",buildingId)
  const selected =  state.complaints.filter((g) => g.buildingId == buildingId);
  return selected.length > 0 ? selected : [];
};
export default complaintsSlice.reducer;
