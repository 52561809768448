import { combineReducers } from "@reduxjs/toolkit";
import me from "./meSlice";
import productRelatedSlice from "./productRelatedSlice";
import navigation from "./navigationSlice";
import users from "./usersSlice";
import buildings from "./buildingSlice";
import units from "./unitSlice";
import groups from "./groupSlice";
import settings from "./settingsSlice";
import tasks from "./tasksSlice";
import finance from "./financeSlice";
import complaints from "./complaintsSlice";
import gLoader from "./gLoaderSlice";
import gModal from "./gModalSlice";
import balances from "./balanceSlice";

const reducers = combineReducers({
  me,
  ...productRelatedSlice,
  navigation,
  users,
  buildings,
  units,
  groups,
  settings,
  tasks,
  finance,
  complaints,
  gLoader,
  gModal,
  balances,
});

const rootReducer = (state, action) => {
  if (action.type === "me/logOut") {
    state = undefined;
  }
  return reducers(state, action);
};

export default rootReducer;
