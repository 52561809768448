import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import ServiceApi from "../../api/ServiceApi";
import { getGroups } from "./groupSlice";
export const financeSlice = createSlice({
  name: "finance",
  initialState: [],
  reducers: {
    setFinance: (state, action) => {
      return action.payload;
    },
  },
});

export const { setFinance } = financeSlice.actions;
export const getFinances = (groupId, onSuccess) => async (dispatch) => {
  const finance = await ServiceApi().getFinances(groupId);
  if (finance) {
    onSuccess && onSuccess(finance);
    dispatch(setFinance(finance));
    // dispatch(getGroups());
  }
};

export const selectFinance = (state) => state.finance;
// export const selectFinancesByGroupId = (state, groupId) =>
//   (state.finances || []).filter((b) => b.groupId == groupId);

// export const selectFinanceById = (state, id) => {
//   console.log("selectFinanceById", id);
//   const selected = state.finances.filter((g) => g.id == id);
//   console.log(selected);
//   return selected.length > 0 ? selected[0] : null;
// };
export default financeSlice.reducer;
