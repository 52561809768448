import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import ServiceApi from "../../api/ServiceApi";
import { setGLoader } from "./gLoaderSlice";
export const groupsSlice = createSlice({
  name: "groups",
  initialState: [],
  reducers: {
    setGroups: (state, action) => {
      return action.payload;
    },
  },
});

export const { setGroups } = groupsSlice.actions;
export const getGroups = (onSuccess) => async (dispatch) => {
  dispatch(setGLoader(true));

  const groups = await ServiceApi().getAllGroups();
  console.log(groups);
  if (groups) {
    onSuccess && onSuccess(groups);

    dispatch(setGroups(groups));
  }
  dispatch(setGLoader(false));
};

export const createGroup =
  (group, onSuccess, onError) => async (dispatch, getState) => {
    const { response, error } = await ServiceApi().createGroup(group);

    if (response) {
      dispatch(getGroups(onSuccess));
    } else {
      onError(error.data.message);
    }
  };

export const updateGroup =
  (group, onSuccess, onError) => async (dispatch, getState) => {
    console.log("updateGroup");
    console.log(group);

    const { response, error } = await ServiceApi().updateGroup(group);

    if (response) {
      dispatch(getGroups(onSuccess));
    } else {
      onError && onError(error.data.message);
    }
  };
export const deleteGroup =
  (group, onSuccess, onError) => async (dispatch, getState) => {
    const { response, error } = await ServiceApi().deleteGroup(group);

    if (response) {
      dispatch(getGroups(onSuccess));
    } else {
      onError && onError(error.data.message);
    }
  };
export const addService =
  (service, onSuccess, onError) => async (dispatch, getState) => {
    const { response, error } = await ServiceApi().createService(service);

    if (response) {
      dispatch(getGroups(onSuccess));
    } else {
      onError && onError(error.data.message);
    }
  };
export const deleteService =
  (service, onSuccess, onError) => async (dispatch, getState) => {
    const { response, error } = await ServiceApi().deleteService(service);

    if (response) {
      dispatch(getGroups(onSuccess));
    } else {
      onError && onError(error.data.message);
    }
  };
export const addFile =
  (doc, onSuccess, onError) => async (dispatch, getState) => {
    if (doc.file) {
      dispatch(setGLoader(true));

      const data = new FormData();

      data.append("file", doc.file.files[0]);

      const fileUpload = await ServiceApi().uploadFile(data);

      delete doc.file;
      doc.path = fileUpload.response.data.path;
    }

    const { response, error } = await ServiceApi().recordFile(doc);

    if (response) {
      dispatch(getGroups(onSuccess));
    } else {
      onError && onError(error.data.message);
    }
    dispatch(setGLoader(false));
  };
export const deleteFile =
  (item, onSuccess, onError) => async (dispatch, getState) => {
    const { response, error } = await ServiceApi().deleteFile(item);

    if (response) {
      dispatch(getGroups(onSuccess));
    } else {
      onError && onError(error.data.message);
    }
  };

export const updateService =
  (service, onSuccess, onError) => async (dispatch, getState) => {
    const { response, error } = await ServiceApi().updateService(service);

    if (response) {
      dispatch(getGroups(onSuccess));
    } else {
      onError && onError(error.data.message);
    }
  };
export const selectGroups = (state) => state.groups;
export const selectGroupById = (state, groupId) => {
  console.log("selectGroupById", groupId);

  const selected = state.groups.filter((g) => g.id == groupId);
  console.log(selected);
  return selected.length > 0 ? selected[0] : null;
};

export default groupsSlice.reducer;
