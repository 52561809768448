import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import ServiceApi from "../../api/ServiceApi";
import { setGLoader } from "./gLoaderSlice";

export const settingsSlice = createSlice({
  name: "settings",
  initialState: {},
  reducers: {
    setSettings: (state, action) => {
      return action.payload;
    },
  },
});

export const { setSettings } = settingsSlice.actions;

export const getSettings = (onSuccess, onFail) => async (dispatch) => {
  dispatch(setGLoader(true));

  const { response, error } = await ServiceApi().getSettings();
  if (response) {
    dispatch(setSettings(response.data));
    onSuccess && onSuccess(response.data);
  } else {
    onFail && onFail(error.data.message);
  }
  dispatch(setGLoader(false));
};

export const selectSettings = (state) => state.settings;
export const selectRoles = (state) => state.settings.roles;
export const selectRoleRight = (state) =>
  state.settings?.roleRights?.[state.me?.user?.role] || [];

export default settingsSlice.reducer;
