import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import ServiceApi from "../../api/ServiceApi";

export const meSlice = createSlice({
  name: "me",
  initialState: {},
  reducers: {
    setUser: (state, action) => {
      return action.payload;
    },
    logOut: (state, action) => {},
  },
});

export const { setUser, logOut } = meSlice.actions;

export const logIn = (body, onSuccess, onFail) => async (dispatch) => {
  const { response, error } = await ServiceApi().signIn(body);
  if (response) {
    localStorage.setItem("token", response.data.tokens.access.token);
    dispatch(setUser(response.data));
    onSuccess(response.data);
  } else {
    onFail(error.data.message);
  }
};

export const selectUser = (state) => state.me?.user;
export const selectRole = (state) => state.me?.user?.role;

export const selectToken = (state) =>
  _.get(state, "me.tokens.access.token", null);
export default meSlice.reducer;
