import client from "./client";

const ServiceApi = () => {
  const signIn = async (body) => {
    const result = await client.post(`auth/login`, body);
    return result;
  };
  const getSettings = async () => {
    const result = await client.get(`settings`);
    return result;
  };

  const createUnits = async (body) => {
    console.log(JSON.stringify(body));
    const result = await client.post(`units/${body.buildingId}`, body);
    return result;
  };
  const updateUnits = async (body) => {
    const result = await client.patch(`units/${body.id}`, body);
    return result;
  };
  const deleteUnits = async (body) => {
    const result = await client.delete(`units/${body.id}`, body);
    return result;
  };
  const getFinances = async (groupId) => {
    const result = await client.get(`invoices/stats/${groupId}`, {});
    return result.response.data;
  };
  const getBalances = async () => {
    const result = await client.get(`invoices/balance`, {});
    return result.response.data;
  };
  const transferBalance = async (approverId) => {
    const result = await client.patch(
      `invoices/transferBalance/${approverId}`,
      {}
    );
    return result;
  };
  const payInvoice = async (invoiceId) => {
    const result = await client.patch(`invoices/pay/${invoiceId}`, {});
    return result;
  };
  const deleteInvoice = async (invoiceId) => {
    const result = await client.delete(`invoices/delete/${invoiceId}`, {});
    return result;
  };
  const createInvoice = async (body) => {
    const result = await client.post(`invoices/unit/${body.unitId}`, body);
    return result;
  };

  const getAllUnits = async (buildingId) => {
    const { response, error } = await client.get(`units/${buildingId}`);
    if (response) {
      return response.data.results;
    }
  };

  const queryUnits = async (query) => {
    const { response, error } = await client.get(`units?fullName=${query}`);
    if (response) {
      return response.data.results;
    }
  };
  const createProduct = async (body) => {
    console.log(JSON.stringify(body));
    const result = await client.post(`service/products`, body);
    return result;
  };
  const createInventoryProduct = async (body) => {
    console.log(JSON.stringify(body));
    const result = await client.post(`shop/products`, body);
    return result;
  };
  const createUser = async (body) => {
    console.log(JSON.stringify(body));
    const result = await client.post(`users/me/`, body);
    return result;
  };
  const updateUser = async (body) => {
    const result = await client.patch(`users/${body.id}`, body);
    return result;
  };
  const deleteUser = async (body) => {
    const result = await client.delete(`users/${body.id}`, body);
    return result;
  };
  const updateProduct = async (body) => {
    const result = await client.patch(`service/products/${body.id}`, body);
    return result;
  };
  const archive = async (id) => {
    const result = await client.patch(`service/archive/${id}`, {});
    return result;
  };
  const unArchive = async (id) => {
    const result = await client.patch(`service/un-archive/${id}`, {});
    return result;
  };
  const getAllUsers = async () => {
    const { response, error } = await client.get(`users/me/`);
    if (response) {
      return response.data;
    }
  };
  const searchClients = async (query, onSuccess = (data) => console.log()) => {
    const { response, error } = await client.get(`products/client/${query}`);
    if (response) {
      onSuccess && onSuccess(response.data.results);
      return response.data;
    }
  };

  const searchSuppliers = async (
    query,
    onSuccess = (data) => console.log()
  ) => {
    const { response, error } = await client.get(`products/suppliers/${query}`);
    if (response) {
      onSuccess && onSuccess(response.data.results);
      return response.data;
    }
  };
  const getAllProducts = async () => {
    const { response, error } = await client.get(`service/products`);
    if (response) {
      return response.data.results;
    }
  };

  const getAllArchivedProducts = async () => {
    const { response, error } = await client.get(`service/archived`);
    if (response) {
      return response.data.results;
    }
  };
  const getAllInventoryProducts = async () => {
    const { response, error } = await client.get(`shop/products`);
    if (response) {
      return response.data.results;
    }
  };

  const getCategories = async () => {
    const { response, error } = await client.get(
      `products/category?sortBy=name:asc`
    );
    if (response) {
      return response.data.results;
    }
  };

  const getBrands = async () => {
    const { response, error } = await client.get(
      `products/make?sortBy=name:asc`
    );
    console.log(response);
    if (response) {
      return response.data.results;
    }
  };

  const getAllBuildings = async (groupId) => {
    const { response, error } = await client.get(`buildings/me/${groupId}`);
    if (response) {
      return response.data;
    }
  };
  const createBuilding = async (body) => {
    console.log(JSON.stringify(body));
    const result = await client.post(`buildings/me/${body.groupId}`, body);
    return result;
  };
  const updateBuilding = async (body) => {
    const result = await client.patch(`buildings/${body.id}`, body);
    return result;
  };
  const deleteBuilding = async (body) => {
    const result = await client.delete(`buildings/${body.id}`, body);
    return result;
  };

  const getAllGroups = async () => {
    const { response, error } = await client.get(`groups/me`);
    if (response) {
      return response.data;
    }
  };
  const createGroup = async (body) => {
    console.log(JSON.stringify(body));
    const result = await client.post(`groups/me`, body);

    return result;
  };

  const updateGroup = async (body) => {
    const result = await client.patch(`groups/${body.id}`, body);
    return result;
  };
  const deleteGroup = async (body) => {
    const result = await client.delete(`groups/${body.id}`, body);
    return result;
  };
  const createService = async (body) => {
    const result = await client.post(`services/${body.groupId}`, body);
    return result;
  };
  const deleteService = async (body) => {
    const result = await client.delete(
      `services/${body.groupId}/${body.id}`,
      body
    );
    return result;
  };
  const updateService = async (body) => {
    const result = await client.patch(
      `services/${body.groupId}/${body.id}`,
      body
    );
    return result;
  };
  const uploadFile = async (body) => {
    const result = await client.post(`file/upload`, body);
    return result;
  };
  const recordFile = async (body) => {
    const result = await client.post(`file/record`, body);
    return result;
  };
  const deleteFile = async (body) => {
    const result = await client.delete(`file/delete/${body.id}`, body);
    return result;
  };

  const generateBuildingCSV = async (buildingId) => {
    try {
      const { response } = await client.get(`file/building/${buildingId}`);
      console.log(response);
      return response.data.path;
    } catch (err) {
      console.log(err);

      return null;
    }
  };
  const getAllTasks = async () => {
    const { response, error } = await client.get(`tasks`);
    if (response) {
      return response.data;
    }
  };
  const createTask = async (body) => {
    console.log(JSON.stringify(body));
    const result = await client.post(`tasks`, body);

    return result;
  };

  const updateTask = async (body) => {
    const result = await client.patch(`tasks/${body.id}`, body);
    return result;
  };
  const deleteTask = async (body) => {
    const result = await client.delete(`tasks/${body.id}`, body);
    return result;
  };
  const getAllComplaints = async () => {
    const { response, error } = await client.get(`complaints`);
    if (response) {
      console.log(response);
      return response.data;
    }
  };
  const createComplaint = async (body) => {
    console.log(JSON.stringify(body));
    const result = await client.post(`complaints`, body);

    return result;
  };

  const updateComplaint = async (body) => {
    const result = await client.patch(`complaints/${body.id}`, body);
    return result;
  };
  const deleteComplaint = async (body) => {
    const result = await client.delete(`complaints/${body.id}`, body);
    return result;
  };
  return {
    getAllComplaints,
    createComplaint,
    updateComplaint,
    deleteComplaint,
    uploadFile,
    searchSuppliers,
    createInventoryProduct,
    archive,
    unArchive,
    searchClients,
    createProduct,
    updateProduct,
    signIn,
    getAllProducts,
    getAllArchivedProducts,
    getAllInventoryProducts,
    getCategories,
    getBrands,
    getAllUsers,
    createUser,
    updateUser,
    deleteUser,
    getAllGroups,
    createGroup,
    updateGroup,
    deleteGroup,
    getAllBuildings,
    createBuilding,
    updateBuilding,
    deleteBuilding,
    createUnits,
    updateUnits,
    getAllUnits,
    deleteUnits,
    payInvoice,
    getSettings,
    createService,
    updateService,
    deleteService,
    recordFile,
    getAllTasks,
    createTask,
    updateTask,
    deleteTask,
    getFinances,
    deleteFile,
    createInvoice,
    queryUnits,
    generateBuildingCSV,
    deleteInvoice,
    getBalances,
    transferBalance,
  };
};

export default ServiceApi;
