import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import ServiceApi from "../../api/ServiceApi";

export const usersSlice = createSlice({
  name: "users",
  initialState: [],
  reducers: {
    setUsers: (state, action) => {
      return action.payload;
    },
  },
});

export const { setUsers } = usersSlice.actions;

export const getUsers = (onSuccess) => async (dispatch) => {
  const users = await ServiceApi().getAllUsers();
  if (users) {
    onSuccess && onSuccess(users);

    dispatch(setUsers(users));
  }
};

export const createUser =
  (user, onSuccess, onError) => async (dispatch, getState) => {
    const { response, error } = await ServiceApi().createUser(user);

    if (response) {
      dispatch(getUsers(onSuccess));
    } else {
      onError(error.data.message);
    }
  };

export const updateUser =
  (user, onSuccess, onError) => async (dispatch, getState) => {
    const { response, error } = await ServiceApi().updateUser(user);

    if (response) {
      dispatch(getUsers(onSuccess));
    } else {
      onError(error.data.message);
    }
  };
export const deleteUser =
  (user, onSuccess, onError) => async (dispatch, getState) => {
    const { response, error } = await ServiceApi().deleteUser(user);

    if (response) {
      dispatch(getUsers(onSuccess));
    } else {
      onError(error.data.message);
    }
  };

export const selectUsers = (state) => state.users;
export default usersSlice.reducer;
