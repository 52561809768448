import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import ServiceApi from "../../api/ServiceApi";
import _ from "lodash";
import { setGLoader } from "./gLoaderSlice";
import { getBalances } from "./balanceSlice";
import { getComplaints } from "./complaintsSlice";
export const unitsSlice = createSlice({
  name: "units",
  initialState: [],
  reducers: {
    setUnits: (state, action) => {
      return action.payload;
    },
  },
});

export const { setUnits } = unitsSlice.actions;

export const getUnits = (buildingId, onSuccess) => async (dispatch) => {
  dispatch(setGLoader(true));

  const units = await ServiceApi().getAllUnits(buildingId);
  if (units) {
    onSuccess && onSuccess(units);
    dispatch(setUnits(units));
  }
  dispatch(setGLoader(false));
};
export const queryUnits = (query, onSuccess) => async (dispatch) => {
  dispatch(setGLoader(true));

  const units = await ServiceApi().queryUnits(query);
  if (units) {
    onSuccess && onSuccess(units);
    dispatch(setUnits(units));
  }
  dispatch(setGLoader(false));
};
export const createUnit =
  (unit, onSuccess, onError) => async (dispatch, getState) => {
    dispatch(setGLoader(true));

    const { response, error } = await ServiceApi().createUnits(unit);

    if (response) {
      dispatch(getUnits(unit.buildingId, onSuccess));
    } else {
      onError(error.data.message);
    }
    dispatch(setGLoader(false));
  };

export const updateUnit =
  (unit, onSuccess, onError) => async (dispatch, getState) => {
    console.log(unit);
    dispatch(setGLoader(true));

    const { response, error } = await ServiceApi().updateUnits(unit);

    if (response) {
      dispatch(getUnits(unit.buildingId, onSuccess));
    } else {
      onError(error.data.message);
      dispatch(setGLoader(false));
    }
  };

export const deleteUnit =
  (unit, onSuccess, onError) => async (dispatch, getState) => {
    dispatch(setGLoader(true));

    const { response, error } = await ServiceApi().deleteUnits(unit);

    if (response) {
      dispatch(getUnits(unit.buildingId, onSuccess));
    } else {
      onError && onError(error.data.message);
      dispatch(setGLoader(false));
    }
  };
export const payInvoice =
  (unit, invoiceId, onSuccess, onError) => async (dispatch, getState) => {
    console.log(unit);
    dispatch(setGLoader(true));

    const { response, error } = await ServiceApi().payInvoice(invoiceId);

    if (response) {
      dispatch(getUnits(unit.buildingId, onSuccess));
      dispatch(getBalances());
    } else {
      onError(error.data.message);
      dispatch(setGLoader(false));
    }
  };
export const deleteInvoice =
  (buildingId, invoiceId, onSuccess, onError) => async (dispatch, getState) => {
    dispatch(setGLoader(true));

    const { response, error } = await ServiceApi().deleteInvoice(invoiceId);

    if (response) {
      dispatch(getUnits(buildingId, onSuccess));
    } else {
      onError(error.data.message);
      dispatch(setGLoader(false));
    }
  };
export const createInvoice =
  (invoice, onSuccess, onError) => async (dispatch, getState) => {
    console.log(invoice);
    dispatch(setGLoader(true));

    const { response, error } = await ServiceApi().createInvoice(invoice);

    if (response) {
      dispatch(getUnits(invoice.buildingId, onSuccess));
    } else {
      onError && onError(error.data.message);
      dispatch(setGLoader(false));
    }
  };

export const createComplaint =
  (complaint, onSuccess, onError) => async (dispatch, getState) => {
    dispatch(setGLoader(true));

    const { response, error } = await ServiceApi().createComplaint(complaint);

    if (response) {
      if(complaint.unitId){
      dispatch(getUnits(complaint.buildingId, onSuccess));
      }else{
        dispatch(getComplaints(onSuccess))
      }
    } else {
      onError(error.data.message);
      dispatch(setGLoader(false));
    }
  };

export const selectAllUnits = (state) => state.units;
export const selectUnitInvoices = (state, unitId) => {
  const selected = state.units.filter((g) => g.id == unitId);
  return selected.length > 0 ? selected[0].invoices : [];
};
export const selectUnitComplaints = (state, unitId) => {
  const selected = state.units.filter((g) => g.id == unitId);
  return selected.length > 0 ? selected[0].complaints : [];
};
export default unitsSlice.reducer;
